import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { ResourceOpportunity, ResourceOpportunityService } from '@klickdata/core/opportunity';
import { AppScope, Resource, ResourceData } from '@klickdata/core/resource';
import {
    Filter,
    GlobalFilterProperty,
    GlobalFilterPropertyType,
    SelectFilterOption,
    StatusFilterOption,
    TableFilterComponent,
    TableSource,
} from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { ShareService } from '@klickdata/shared-components/src/share/share.service';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import * as moment from 'moment';
import { BehaviorSubject, filter, map, switchMap, takeUntil } from 'rxjs';
import { ResourceCompetenceListService } from './resource-competence-list.service';
import { DownloadHelperService } from 'apps/klickdata/src/app/shared/dialog/download-pdf-dialog/download-helper.service';

@Component({
    selector: 'app-resources-competence-list',
    templateUrl: './resources-competence-list.component.html',
    styleUrls: ['./resources-competence-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ResourceCompetenceListService],
})
export class ResourcesCompetenceListComponent extends OnDestroyHandler implements OnInit, AfterViewInit {
    @Input() user: User;
    @Input() tableFilterItems: Filter<string | number>[];
    @Input() canDownloadRes: boolean;
    public dataSource = new TableSource<Resource>();
    @Input() columns = ['columns', 'title', 'occasionStatus', 'article_code', 'last_action', 'tools'];
    @Output() onUpdateRecurring: EventEmitter<ResourceOpportunity> = new EventEmitter();
    public isMobile: boolean;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    public shareLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    AppScope = AppScope;
    GlobalFilterProperty = GlobalFilterProperty;
    GlobalFilterPropertyType = GlobalFilterPropertyType;
    public resourceStatusOptions: StatusFilterOption[];
    public type_scope_ids = [AppScope.MATERIAL, AppScope.TEST, AppScope.COURSE];
    public resourceTypeOptions: SelectFilterOption[];

    constructor(
        protected resourceService: ResourceCompetenceListService,
        protected downloadHelper: DownloadHelperService,
        protected mobile: MobileService,
        protected cdRef: ChangeDetectorRef,
        public shareService: ShareService,
        protected resourceOpportunityService: ResourceOpportunityService
    ) {
        super();
        this.resourceStatusOptions = [
            { title: $localize`Done`, value: 'done', color: '#3e5365', icon: 'done_all', selected: true },
            { title: $localize`Ongoing`, value: 'ongoing', color: '#ff9961', icon: 'cached', selected: true },
            { title: $localize`Deleted`, value: 'deleted', color: '#ff9961', icon: 'delete_forever', selected: true },
            {
                title: $localize`Not Started`,
                value: 'not_started',
                color: '#93cbd1',
                icon: 'remove_done',
                selected: true,
            },
            { title: $localize`Overdue`, value: 'overdue', color: '#a7a7a7', icon: 'event_busy', selected: true },
            { title: $localize`Cancelled`, value: 'cancelled', color: '#e44a66', icon: 'cancel', selected: true },
        ];
        this.resourceTypeOptions = Utils.getResourceTypeOptions();
    }

    public ngOnInit() {
        this.dataSource.service = this.resourceService;
    }

    public ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        const appliedFilters = [];
        if (!!this.tableFilterItems?.length) {
            this.tableFilterItems.forEach((filterItem) => {
                appliedFilters.push(new Filter(filterItem.property, filterItem.items));
            });
        }
        appliedFilters.push(new Filter('eager', ['competence']));
        this.filter.createOrUpdateWithoutEmitFilter(appliedFilters);
    }
    updateComp(resource: Resource) {
        const competence = { ...resource, ...{ done_at: resource.opportunity_done } };
        if (!!competence.done_at && moment(competence.done_at).year() == 1970) {
            competence.done_at = null;
        }

        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.UPDATE_COMPETENCE,
            data: {
                type: 'updateCompetence',
                comp: competence,
            },
        });
        this.mobile
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                takeUntil(this.destroy),
                switchMap(() =>
                    this.mobile.getSideNavResponseData().pipe(
                        filter((data) => data.type == 'updateCompetence'),
                        map((data) => data.value)
                    )
                ),
                switchMap((data) =>
                    competence.opportunity_id
                        ? this.resourceOpportunityService.update(
                              { id: competence.opportunity_id, done: data.start_date },
                              'competence'
                          )
                        : this.resourceOpportunityService.createOpportunity(
                              {
                                  resource_id: competence.id,
                                  user_id: this.user.id.toString(),
                                  done: data.start_date,
                              },
                              'competence'
                          )
                )
            )
            .subscribe((occasion) => {
                this.dataSource.refresh();
                this.onUpdateRecurring.emit(occasion);
                this.cdRef.markForCheck();
            });
    }
    public getCircleMetadata(resource: ResourceData): { value: any; class: string; tooltip: any } {
        const userCompetence = { ...resource, ...{ done_at: resource.opportunity_done } };
        const isValidDate =
            userCompetence && userCompetence?.done_at && moment(userCompetence?.done_at).year() !== 1970;

        return {
            value: userCompetence.competence_label,
            class: userCompetence.competence_status,
            tooltip: isValidDate ? moment(userCompetence.done_at).format('YYYY-MM-DD') : '',
        };
    }
    public downloadResource(resource: Resource) {
        this.downloadHelper.downloadWithOptions(resource.downloads);
    }
}
